:root {
  --display-loader: none;
}
@font-face {
  font-family: "Gotham SSm A";
  src: url("../public/fonts/725262BC71949F842.woff2")format('woff2'), url("../public/fonts/725262BC71949F842.woff")format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Letter Gothic';
  src: url("../public/fonts/LetterGothic12PitchBT.ttf");
}
body {
  font-family: Gotham SSm A, Gotham SSm B, Arial, sans-serif;
  font-size: 12px;
  line-height: 1.42857143;
  -webkit-font-smoothing: antialiased;
}
.list-none {
  list-style: none;
}
a {
  text-decoration: none;
}
.disclaimer {
  display: var(--disclaimer-display, block);
}
.spinner-border{
  width: 40px;
  height: 40px;
  position: absolute;
  left: 48%;
  top: 40%;
  border-width: 4px;
}
#loadingDiv {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  display: var(--display-loader);
}
#my-tooltip{
  z-index: 1;
  width: 350px;
}
.form-control:focus{
  box-shadow: none;
  border-color: unset;
  border: 1px solid #e5e5e5 !important;
}
.invalid-feedback {
  color: #a94442;
  font-size: 12px;
}
span.tooltipIcon {
  display: inline-block;
  width: 36px !important;
  height: 36px;
  border-left: 0;
  cursor: pointer;
  position: absolute;
}
span.tooltipIcon:after {
  width: 24px;
  height: 24px;
  display: block;
  background-color: #999;
  margin: 6px 0 0 12px;
  border-radius: 100%;
  text-align: center;
  color: #fff;
  font: normal normal normal 14px / 1 FontAwesome;
  display: inline-block;
  font-size: inherit;
  text-rendering: auto;
  font-size: 24px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: inherit;
  content: "\f129";
  font-size: 14px !important;
  line-height: 24px;
}
:after, :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
span.tooltipIcon.is-invalid:after {
  background-color: #ff4c4c;
  content: "\f00d";
}
span.tooltipIcon.is-valid:after {
  background-color: #43d5b0;
  content: "\f00c";
}
.invalid-feedback {
  display: block !important;
}
.invalid-feedback-none{
  display: none;
}
.cloud.info {
  background-color: #999;
  display: none;
}

.cloud {
  z-index: 1;
  padding: 10px;
  color: #fff;
  margin-top: 10px;
  display: block;
  margin-right: -20px;
}
.cloud.info:before {
  left: 92%;
  margin-left: 3px;
  border-width: 8px;
  border-bottom-color: #999;
}

.cloud.info:before {
  position: absolute;
  bottom: 100%;
  width: 0;
  height: 0;
  border: solid transparent;
  content: " ";
  pointer-events: none;
}
.modal-title {
  font-size: 14px;
  flex-grow: 1;
  text-align: center;
}
.close{
  order: 1;
}
.closeBtn {
  background-color: #2e2e2e;
  color: #fff;
  width: 120px;
  border-radius: 0;
}
.closeBtn:hover {
  background-color: #2e2e2e;
  color: #fff;
}
.modal-footer {
  align-self: center;
  border: 0;
}
.modal-dialog {
  text-align: left;
  vertical-align: middle;
  max-width: 550px;
  top: 40%;
}
.modal-content {
  border-radius: 0;
}
.modal-body {
  padding-bottom: 0;
}
.privacylink {
  color: black;
}